import { css } from "@emotion/react";
import Image from "next/image";

import { breakpoints } from "@mytutor/shared-react-web-components/lib/theme";

const featureStyle = (
  reverseRow: boolean,
  backgroundColour: string,
  textColour: string
) => css`
  height: 100%;
  background-color: ${backgroundColour};

  .container {
    display: flex;
    flex-direction: ${reverseRow ? "row-reverse" : "row"};
    align-items: center;
    justify-content: center;
    max-width: 1265px;
    margin: 0 auto 0;
    padding: 48px 96px;
    overflow: hidden;

    @media (max-width: ${breakpoints.desktop}px) {
      padding: 48px 32px;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      flex-direction: column-reverse;
      align-items: center;
      max-width: 460px;
      padding: 16px 24px;
    }

    @media (max-width: ${breakpoints.phone}px) {
      flex-direction: column-reverse;
      align-items: center;
      max-width: 460px;
      padding: 16px 24px;
    }
  }

  h2 {
    font-size: 2.75em;
    font-weight: 500;
    line-height: 1.25em;
    width: 96%;
    float: ${reverseRow ? "right" : "left"};
    margin: 0 0 20px;
    color: ${textColour};

    @media (max-width: ${breakpoints.largeDesktop}px) {
      font-size: 2.4em;
    }

    @media (max-width: ${breakpoints.desktop}px) {
      font-size: 2.125em;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 1.5em;
      margin: 20px 0;
      width: 100%;
      float: none;
    }
  }

  p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.72rem;
    width: 96%;
    float: ${reverseRow ? "right" : "left"};
    margin: 0 0 20px;
    color: ${textColour};

    @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
      float: none;
    }
  }

  .dataHolder {
    padding: 0px 0 0 0;
    width: 96%;

    @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
    }
  }

  .imgContainer {
    width: 75%;
    float: ${reverseRow ? "left" : "right"};

    @media (max-width: ${breakpoints.desktop}px) {
      width: 90%;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      width: 80%;
      padding-left: ${reverseRow ? "20%" : ""};
      padding-right: ${reverseRow ? "" : "20%"};
    }
  }

  #how-it-works {
    // to accommodate for the navbar
    position: relative;
    top: -60px;
  }
`;

type Props = {
  top?: boolean;
  title: string;
  backgroundColour: string;
  textColour: string;
  paragraphSectionOne: string;
  paragraphSectionTwo?: string;
  image: string;
  imageAlt: string;
  reverseRow: boolean;
};
const Feature = ({
  top,
  title,
  backgroundColour,
  textColour,
  paragraphSectionOne,
  paragraphSectionTwo,
  image,
  imageAlt,
  reverseRow,
}: Props) => (
  <div css={featureStyle(reverseRow, backgroundColour, textColour)}>
    {top && <a id="how-it-works" />}
    <div className="container">
      <div className="dataHolder">
        <h2>{title}</h2>
        <p>{paragraphSectionOne}</p>
        <p>{paragraphSectionTwo}</p>
      </div>

      <div className="dataHolder">
        <div className="imgContainer">
          <Image
            src={image}
            alt={imageAlt}
            width={452}
            height={359}
            layout="responsive"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Feature;
